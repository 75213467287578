import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { typography } from "../../../theme/typography";
import { common, danger, neutral } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { ReactComponent as PasteIcon } from "../../../assets/paste.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/error.svg";
import useAuthenticatedFetch from "../../../auth/authenticated";
import { FEEDBACK } from "../../../models/Feedback";
import { AUTO_HIDE_DURATION_ERROR } from "../../../Common";
import { ReactComponent as CheckCircleIcon } from "../../../assets/check-circle.svg";
import { ReactComponent as CloseIconWhite } from "../../../assets/close-white.svg";
import { modalBoxStyle, modalCardStyle } from "../../../theme/theme";

interface AssignToClaimModalProps {
  open: boolean;
  /**
   * Callback function to handle modal close
   * @param event - Event object from the close event
   */
  onClose: (event?: any) => void;
  mailId: string;
  mailCounterpart: string;
  /**
   * View update callback
   * @param newStatus
   */
  updateToNewStatus: (newStatus: string) => void;
  mailsSchema: any;
}

const AssignToClaimModal: React.FC<AssignToClaimModalProps> = ({
  open,
  onClose,
  mailId,
  mailCounterpart,
  updateToNewStatus,
  mailsSchema,
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const getAccessHeader = useAuthenticatedFetch();

  // state hooks
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [claimIdError, setClaimIdError] = useState(false);
  const [claimHasMissingData, setClaimHasMissingData] = useState(false);
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const [claimId, setClaimId] = useState("");
  const [counterpart, setCounterpart] = useState("");
  
  const resetAndClose = () => {
    setTextFieldValue("");
    setIsLoading(false);
    setClaimIdError(false);
    setClaimHasMissingData(false);
    setCounterpart("");
    // we do not reset setClaimId cause used after modal closed
    onClose();
  };

  const handleTextFieldChange = (event) => {
    setClaimIdError(false);
    setTextFieldValue(event.target.value);
  };

  const counterPartUnknown = () : boolean => {
    return mailCounterpart === "UNKNOWN";
  }

  const handleCounterpartChange = (event) => {
    setCounterpart(event.target.value);
    setClaimHasMissingData(false);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      // save claim id that will be shown in custom feedback with link to claim when modal closed or on error
      setClaimId(textFieldValue);

      const response = await fetch("/api/actions", {
        method: "POST",
        headers: await getAccessHeader(),
        body: JSON.stringify({
          mail_id: mailId,
          claim_id: textFieldValue,
          counterpart: counterPartUnknown() ? counterpart : null,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        setShowFeedback(FEEDBACK.SUCCESS);
        // Update List - remove mail cause connected with a claim action now
        updateToNewStatus("Spam");
        resetAndClose();
      } else {
        setIsLoading(false);
        setShowFeedback(FEEDBACK.ERROR);
        // reset previous and display error cases relevant for FE
        if (result.error.startsWith("Invalid claim_id") || result.error.startsWith("Claim not found")) {
          setClaimHasMissingData(false);
          setClaimIdError(true);
        } else if (result.error.startsWith("Inconsistent Claim data")) {
          setClaimIdError(false);
          setClaimHasMissingData(true);
        } else {
          console.error(result.error);
        }
      }
    } catch (error: any) {
      console.log("Error:", error.message);
      setIsLoading(false);
      setShowFeedback(FEEDBACK.ERROR);
    }
  };

  const handlePaste = async () => {
    const pastedText: string = await navigator.clipboard.readText();
    setTextFieldValue(pastedText.trim());
  };

  const handleFeedbackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // ignore clicks that are not on the close icon of the alert
    if (reason === "clickaway") {
      return;
    }

    setShowFeedback(FEEDBACK.NONE);
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={modalBoxStyle}>
          <Card sx={modalCardStyle}>
            <CardHeader
              sx={{ paddingBottom: "8px" }}
              title={
                <Typography variant="h5">
                  {t("ADMIN.MAILS.ASSIGN_CLAIM")}
                </Typography>
              }
              action={
                <IconButton onClick={() => resetAndClose()}>
                  <CloseIcon />
                </IconButton>
              }
            ></CardHeader>

            <CardContent>
              {/* Input Field */}
              <TextField
                sx={{
                  mb: "10px",
                  "& .MuiInputBase-input": {
                    ...typography.titleMd,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: !textFieldValue ? "1px dashed" : "1px solid",
                    borderBottomColor:
                      mode === "light" ? neutral[200] : neutral[600],
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor:
                      mode === "light" ? neutral[200] : neutral[600],
                  },
                }}
                label={t("ADMIN.MAILS.ASSIGN_CLAIM_INPUT_LABEL")}
                variant="standard"
                fullWidth
                value={textFieldValue}
                onChange={handleTextFieldChange}
                disabled={isLoading}
                helperText={
                  claimIdError ? t("ADMIN.MAILS.ASSIGN_CLAIM_ERROR") : ""
                }
                error={claimIdError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : claimIdError ? (
                        <ErrorIcon />
                      ) : (
                        <Tooltip title={t("DASHBOARD.INPUT_PASTE")}>
                          <IconButton onClick={handlePaste} size="small">
                            <PasteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              {/* Link to claim on error */}
              {claimHasMissingData && (
                <>
                  <Typography
                    variant="bodySm"
                    sx={{ mt: "10px", color: danger[500] }}
                  >
                    {t("ADMIN.MAILS.CLAIM_HAS_MISSING_DATA", {counterpart: counterpart ? counterpart : mailCounterpart}) + " "}
                    <Link
                      component="button"
                      variant="bodySm"
                      color={danger[500]}
                      onClick={() => window.open(`/claims/${claimId}`)}
                    >
                      {t("ADMIN.MAILS.CLAIM_LINK_TEXT", {counterpart: counterpart ? counterpart : mailCounterpart})}
                    </Link>
                  </Typography>
                </>
              )}

              {/* Counterpart selection when counterpart from mail is unknown */}
              {counterPartUnknown() && (
                <FormControl sx={{ mt: "15px" }}>
                  <Typography variant="titleLg">{t("ADMIN.MAILS.SELECT_COUNTERPART")}</Typography>
                  <RadioGroup
                    aria-label="options"
                    value={counterpart}
                    onChange={handleCounterpartChange}
                >
                    {mailsSchema.counterpart.constraints.options
                        .filter((option) => option === "CLIENT" || option === "CARRIER")
                        .map((option) => (
                            <FormControlLabel
                              value={option}
                              key={option}
                              control={<Radio />}
                              label={t("ADMIN.MAIL_TYPE." + option)}
                            />
                        ))}
                  </RadioGroup>
                </FormControl>
              )}

              {/* CTA´s */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 4,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    width: "267px",
                    height: "48px",
                    color: mode === "light" ? neutral[700] : neutral[200],
                  }}
                  onClick={() => resetAndClose()}
                >
                  {t("GENERAL.CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "267px",
                    height: "48px",
                  }}
                  tabIndex={7}
                  onClick={handleSave}
                  disabled={
                    !textFieldValue.trim() ||
                    (counterPartUnknown() && counterpart.length === 0) ||
                    isLoading
                  }
                >
                  {t("GENERAL.SAVE")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>

      {/* Custom Feedback for this modal */}
      {showFeedback !== FEEDBACK.NONE && (
        <Snackbar
          open={true}
          autoHideDuration={
            showFeedback === FEEDBACK.SUCCESS ? 10000 : AUTO_HIDE_DURATION_ERROR
          }
          onClose={handleFeedbackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            variant="filled"
            severity={showFeedback === FEEDBACK.SUCCESS ? "success" : "error"}
            icon={
              showFeedback === FEEDBACK.SUCCESS ? (
                <CheckCircleIcon />
              ) : (
                <ErrorIcon />
              )
            }
            sx={{ color: common["white"] }}
            action={
              <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                <CloseIconWhite />
              </IconButton>
            }
          >
            {showFeedback === FEEDBACK.ERROR
              ? t("GENERAL.ERROR")
              : t("GENERAL.SAVED")}

            {showFeedback === FEEDBACK.SUCCESS && (
              <Link
                component="button"
                variant="bodySm"
                color={common["white"]}
                sx={{ display: "block", marginTop: "5px" }}
                onClick={() => window.open(`/claims/${claimId}`)}
              >
                {t("TABLE.OPEN_CLAIM_VIEW")}
              </Link>
            )}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AssignToClaimModal;
