import { Avatar, Box, Typography, styled } from "@mui/material";
import { common, neutral } from "../../../theme/colors";
import { ReactComponent as ArrowForwardIcon } from '../../../assets/attachment/arrow_forward_filled.svg';
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import React, {useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionMail } from "../../../models/Action";

// MUI style overrides
const MailSpan = styled(Box) (() => ({
        position: 'absolute',
        textAlign: 'left',
        right: '0',
        marginTop: '8%',
        display: 'block',
        padding: '4px 12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 1000,
        width: 'max-content'
    }));

const CardContentFrame = styled(Box)(() => ({
    borderRadius: '8px',
    padding: '8px 16px',
    maxWidth: '640px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
}));

interface ClaimActionMailHeaderProps {
    a: ActionMail;
}

const ClaimActionMailHeader: React.FC<ClaimActionMailHeaderProps> = ({a}) => {
    const { t } = useTranslation();
    const {mode} = useThemeContext();
    const typographyRef = useRef<HTMLElement | null>(null);
    const [modalOpen, setModalOpen] = useState(false)

    const handleClickOutside = (event) => {
        if (typographyRef.current && !typographyRef.current.contains(event.target)) {
            setModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const createAvatar = (name: string) : string => {
        if (!name) {
            return '?';
        }

        if (name.length > 2) {
            return name.slice(0, 2).toUpperCase();
        }

        return name;
    }

    return <CardContentFrame sx={{border: `1px solid ${mode === 'light' ? neutral[200] : neutral[600]}`,}}>
        <Box sx={{
            width: '45%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            wordBreak: 'break-word'
        }}>
            {(a.tenant_shortname || a.counterpart_shortname) &&
                <Avatar>
                    {createAvatar(a.direction === 'OUTBOUND' ? a.tenant_shortname : a.counterpart_shortname)}
                </Avatar>
            }

            <Box sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                ml: 1
            }}>
                {(a.tenant_shortname || a.counterpart_shortname) &&
                <Typography
                    variant='titleLg'>{a.direction === 'OUTBOUND' ? a.tenant_shortname : a.counterpart_shortname}</Typography>
                }
                <Typography variant='bodySm' sx={{color: mode === 'light'? neutral[700] : neutral[200]}}>{a.from_address}</Typography>
            </Box>
        </Box>
        <ArrowForwardIcon />
        <Box sx={{
            width: '45%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            wordBreak: 'break-word'
        }}>
            <Box sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                textAlign: 'end',
                mr: 1
            }}>
                {(a.tenant_shortname || a.counterpart_shortname) &&
                <Typography variant='titleLg'>{a.direction === 'OUTBOUND' ? a.counterpart_shortname : a.tenant_shortname}</Typography>
                }
                { a.to.length === 1 ?
                    <Typography variant='bodySm' sx={{color: mode === 'light'? neutral[700] : neutral[200]}}> {a.to} </Typography>
                    :
                    <Typography
                        ref={typographyRef as any}
                        onClick={() => setModalOpen(!modalOpen)}
                        variant='bodySm'
                        sx={{
                            color: mode === 'light'? neutral[700] : neutral[200],
                            cursor:'pointer',
                            position:'relative',
                            borderBottom: '1px solid ' + (mode === 'light' ? neutral[700] : neutral[200])
                        }}
                    >
                        { t('DASHBOARD.ACTIONS.SEE_ALL_RECIPIENTS', { count: a.to.length}) }

                        {modalOpen &&
                            <div onClick={(e) => e.stopPropagation()} style={{cursor:'default'}}>
                                <MailSpan
                                    style={{
                                        backgroundColor: mode==='dark' ? neutral[900] : common["white"],
                                        border: `1px solid ${mode === 'light' ? neutral[200] : neutral[600]}`}}
                                >
                                    { a.to.map((item) => {
                                        return <Typography
                                            key={item}
                                            variant="bodyMd"
                                            component="div"
                                            sx={{
                                                padding:'6px 0px',
                                                color: mode === 'light'? neutral[700] : neutral[200],
                                            }}
                                        >
                                            {item}
                                        </Typography>
                                    })}
                                </MailSpan>
                            </div>
                        }
                    </Typography>
                }
            </Box>
            {(a.tenant_shortname || a.counterpart_shortname) &&
            <Avatar>
                {createAvatar(a.direction === 'OUTBOUND' ? a.counterpart_shortname : a.tenant_shortname)}
            </Avatar>
            }
        </Box>
    </CardContentFrame>;
}

export default ClaimActionMailHeader;
