import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip, CircularProgress,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { ReactComponent as AttachmentIcon } from "../../../assets/attachment/attachment.svg";
import { ReactComponent as MailInIcon } from "../../../assets/attachment/mail_in.svg";
import { ReactComponent as MailOutIcon } from "../../../assets/attachment/mail_out.svg";
import { ReactComponent as InfoIcon } from "../../../assets/attachment/info.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/attachment/preview.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/table/delete.svg";
import { ReactComponent as SendMailIcon } from "../../../assets/send-mail.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ClaimActionMailHeader from "./ClaimActionMailHeader";
import ClaimActionMailAttachment, {
  CardContentFrame,
} from "./ClaimActionMailAttachment";
import { danger, neutral, primary } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { formatDate } from "../../../Common";
import { MailFromChip } from "./MailFromChip";
import { typography } from "../../../theme/typography";
import DeleteActionEmailModal from "../modals/DeleteActionEmailModal";
import { Action } from "../../../models/Action";
import useAuthenticatedFetch from "../../../auth/authenticated";
import { User } from "../../../models/User";

interface ClaimActionProps {
  action: Action;
  isOpen: boolean;
  attachmentSchema: any;
  error: boolean;
  user: User;
  handleDeleteEmail: Function;
  /**
   * Feedback callback to parent
   * @param success - true for success / false for error
   * @param successTextToken - optional success text token - Default is 'GENERAL.SAVED'.
   */
  triggerFeedback: (success: boolean, successTextToken?: string) => void;
}

const ClaimAction: React.FC<ClaimActionProps> = ({
  action,
  isOpen,
  attachmentSchema,
  error,
  user,
  handleDeleteEmail,
  triggerFeedback,
}) => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  // state hooks
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [showDeleteActionModal, setShowDeleteActionModal] = useState(false);
  const [expanded, setExpanded] = useState(isOpen);
  const [a, setAction] = useState<Action>(action);
  const [sendEmailPending, setSendEmailPending] = useState(false);

  const mailNotSend: boolean = a.initiator_type === "SYSTEM" && a.mail?.direction === "OUTBOUND" && a.mail?.processed === "FALSE";

  /**
   * Callback from delete action modal
   */
  const triggerDelete = () => {
    handleDeleteEmail(a.id);
  };

  /**
   * Delete mail click handler
   * @param e
   */
  const clickOnDeleteMail = (e: React.MouseEvent) => {
    // prevent accordion to open
    e.stopPropagation();
    setShowDeleteActionModal(true)
  }

  /**
   * Send mail click handler
   * @param e
   */
  const clickOnSendMail = async (e: React.MouseEvent) => {
    // prevent accordion to open
    e.stopPropagation();
    setSendEmailPending(true)

    const response = await fetch(`/api/mails/${a.mail?.id}/send`, {
      method: "PATCH",
      headers: await getAccessHeader(),
    });

    const result = await response.json();

    if (result.success) {
      // update view - removes send mail button
      setAction((prevAction) => ({
        ...prevAction,
        mail: prevAction.mail ? {
          ...prevAction.mail,
          processed: "TRUE",
        } : null,
      }));

      triggerFeedback(true, 'DASHBOARD.ACTIONS.MAIL_SENT_SUCCESS');
      setSendEmailPending(false);
    } else {
      console.error("Network response was not ok: ", result.message);
      triggerFeedback(false);
      setSendEmailPending(false);
    }
  };

  return (
    <Card sx={{ minHeight: "103px", p: 0, display: "grid" }}>
      <CardHeader
        sx={{
          cursor: a.mail ? "pointer" : "cursor",
          p: "24px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignSelf: "center",
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        avatar={
          <>
            { !a.mail ? <InfoIcon /> :
               a.mail?.direction === "INBOUND" ? <MailInIcon /> : <MailOutIcon />
            }
          </>
        }
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Send mail button */}
            {mailNotSend && (
              <Tooltip title={t("DASHBOARD.ACTIONS.SEND_MAIL")}>
                <IconButton
                  onClick={(event) => clickOnSendMail(event)}
                  sx={{ mr: "10px" }}
                >
                  {sendEmailPending ? <CircularProgress size={20} /> : <SendMailIcon></SendMailIcon>}
                </IconButton>
              </Tooltip>
            )}

            {/* Operator can delete unsent mail */}
            {mailNotSend && (
              <Tooltip title={t("DASHBOARD.ACTIONS.DELETE_MAIL")}>
                <IconButton
                  onClick={(event) => clickOnDeleteMail(event)}
                  sx={{ mr: "16px" }}
                >
                  <DeleteIcon></DeleteIcon>
                </IconButton>
              </Tooltip>
            )}

            {/* From Admin assigned mail to claim can only be deleted from Admin */}
            {user.isAdmin && a.initiator_type === "ADMIN" && (
              <Tooltip title={t("DASHBOARD.ACTIONS.DELETE_MAIL")}>
                <IconButton
                  onClick={(event) => clickOnDeleteMail(event)}
                  sx={{ mr: "16px" }}
                >
                  <DeleteIcon></DeleteIcon>
                </IconButton>
              </Tooltip>
            )}

            {a.mail && a.mail.attachments.length > 0 && (
              <Tooltip title={t("DASHBOARD.ACTIONS.HAS_ATTACHMENTS")}>
                <AttachmentIcon style={{ marginRight: "16px" }} />
              </Tooltip>
            )}

            <Typography
              variant="bodyMd"
              sx={{ color: mode === "light" ? neutral[700] : neutral[200] }}
            >
              {formatDate(a.timestamp)}
            </Typography>
          </Box>
        }
        title={
          <Box sx={{ display: "block" }}>
            {/* Initiator Type chip */}
            {a.initiator_type && (
              <MailFromChip
                size="small"
                mode={mode}
                type={a.initiator_type}
                label={t(
                  "DASHBOARD.ACTIONS.INITIATOR_TYPE." + a.initiator_type,
                )}
              />
            )}

            {/* Mail not send chip */}
            {mailNotSend && (
              <Chip
                size="small"
                label={t("DASHBOARD.ACTIONS.MAIL_NOT_SENT")}
                sx={{
                  mb: "6px",
                  ml: "6px",
                  backgroundColor: mode === "light" ? danger[700] : danger[800],
                  ...typography.bodyXs,
                  color: "#FFFFFF",
                }}
              ></Chip>
            )}

            {a.description && (
              <Typography
                variant="h4"
                sx={{ display: "block", paddingRight: 2 }}
              >
                {a.description}
              </Typography>
            )}

            {a.mail?.subject && (
              <Typography
                variant="bodySm"
                sx={{ display: "block", paddingRight: 2 }}
              >
                {a.mail.subject}
              </Typography>
            )}
          </Box>
        }
        onClick={() => setExpanded(!expanded)}
      />

      {/* System created actions can have no mail data */}
      {a.mail && (
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ overflow: "auto" }}
        >
          <CardContent sx={{ p: 3 }}>
            <ClaimActionMailHeader a={a.mail} />

            {a.mail.attachments.length > 0 && attachmentSchema && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {showAllAttachments || a.mail.attachments.length < 7 ? (
                  <>
                    {a.mail.attachments.map((attachment, attachmentIndex) => (
                      <ClaimActionMailAttachment
                        attachmentSchema={attachmentSchema}
                        key={attachmentIndex}
                        attachment={attachment}
                        error={error}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {a.mail.attachments
                      .slice(0, 5)
                      .map((attachment, attachmentIndex) => (
                        <ClaimActionMailAttachment
                          attachmentSchema={attachmentSchema}
                          key={attachmentIndex}
                          attachment={attachment}
                          error={error}
                        />
                      ))}
                    <CardContentFrame
                      sx={{
                        border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`,
                        height: "66px",
                        padding: 0,
                      }}
                    >
                      <Button
                        variant="text"
                        style={{ width: "100%", height: "inherit" }}
                        onClick={() => setShowAllAttachments(true)}
                      >
                        <PreviewIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "8px",
                            fill: primary[600],
                          }}
                        />
                        <p
                          style={{
                            ...typography.bodySm,
                            fontWeight: "700",
                            color: primary[500],
                          }}
                        >
                          {t("DASHBOARD.ACTIONS.ATTACHMENTS.SHOW_ALL")}
                        </p>
                      </Button>
                    </CardContentFrame>
                  </>
                )}
              </Box>
            )}

            <Typography
              variant="bodyLg"
              dangerouslySetInnerHTML={{ __html: a.mail.text }}
            />
          </CardContent>
        </Collapse>
      )}

      <DeleteActionEmailModal
        isOpen={showDeleteActionModal}
        onClose={() => setShowDeleteActionModal(false)}
        handleDeleteEmail={triggerDelete}
      />
    </Card>
  );
};

export default ClaimAction;
