import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FEEDBACK } from "../../models/Feedback";
import useAuthenticatedFetch from "../../auth/authenticated";
import AdminMailDetailsContent from "./AdminMailDetailsContent";
import AdminMailDetailsMenu from "./AdminMailDetailsMenu";
import { ActionMail } from "../../models/Action";
import Feedback from "../ui/Feedback";

interface AdminMailDetailsProps {
  /**
   * Currently active selected Mail
   */
  mailId: string;
  /**
   * useState callback to parent
   */
  handleMailDetailChange: (updatedValue: string) => void;
  /**
   * Navigation button callback to parent
   */
  onIncrement: () => void;
  /**
   * Navigation button callback to parent
   */
  onDecrement: () => void;
  /**
   * Email amount
   */
  totalEmails: number;
  /**
   * Currently active selected Mail index (Row) from the list
   */
  activeIndex: number;
  mailsSchema: any;
  attachmentSchema: any;
}

const AdminMailDetails: React.FC<AdminMailDetailsProps> = ({
  mailId,
  mailsSchema,
  attachmentSchema,
  handleMailDetailChange,
  onDecrement,
  onIncrement,
  totalEmails,
  activeIndex
}) => {
  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  const [mailDetails, setMailDetails] = useState<ActionMail>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const [fetchError, setFetchError] = useState(false);

  // load mail data when active mail changes via click on mail in list or via navigation buttons in menu
  useEffect(() => {
    // abort controller instances created here to keep reference
    const controller = new AbortController();
    const abortSignal = controller.signal;

    // reset feedbacks
    setFetchError(false);
    setIsLoadingData(true);

    const fetchMailDetails = async () => {
      fetch(`/api/mails/${mailId}`, {
        method: "GET",
        headers: await getAccessHeader(false),
        signal: abortSignal,
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setFetchError(true);
            setIsLoadingData(false);
          }
          return response.json();
        })
        .then((data) => {
          setMailDetails(data);
          setIsLoadingData(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            console.log("Pending request aborted for mailId %s. Another Email was requested.", mailId);
          } else {
            console.error("Error fetching mail data:", error);
            setFetchError(true);
            setIsLoadingData(false);
          }
        });
    };

    fetchMailDetails().then();

    // Abort the pending fetch request on component unmount or mailId change
    return () => controller.abort();
    // eslint-disable-next-line
  }, [mailId]);

  // set from "Unread" to "Read" automatically when ensured that mail data is available but allow setting it back to "Unread"
  useEffect(() => {
    if (!isLoadingData && mailDetails?.read_status === "Unread") {
      updateMailReadStatus("Read").then();
    }
    // eslint-disable-next-line
  }, [isLoadingData]);

  const updateMailReadStatus = async (updatedValue: string) => {
    try {
      const response = await fetch(`/api/mails/${mailDetails?.id}`, {
        method: "PATCH",
        headers: await getAccessHeader(),
        body: JSON.stringify({ read_status: updatedValue }),
      });

      const result = await response.json();

      if (result.success) {
        updateViewWithNewStatus(updatedValue);
        triggerFeedback(true);
      } else {
        triggerFeedback(false);

        return result.error;
      }
    } catch (error: any) {
      console.log("Error:", error.message);
      triggerFeedback(false);

      return `Error`;
    }
  };

  /**
   * Callback for the Feedback message
   * @param success
   */
  const triggerFeedback = (success: boolean) => {
    setShowFeedback(success ? FEEDBACK.SUCCESS : FEEDBACK.ERROR);
  };

  /**
   * Callback to update the view / data model to new read status value
   * @param newStatus
   */
  const updateViewWithNewStatus = (newStatus: string) => {
    setMailDetails((prevMailDetails) => {
      // linting fix
      if (!prevMailDetails) {
        return;
      }

      // update dropdown in Menu
      return {
        ...prevMailDetails,
          read_status: newStatus
      };
    });

    // update status in parent / list
    handleMailDetailChange(newStatus);
  };

  return fetchError ? (
    <Box sx={{ mt: "100px", textAlign: "center" }}>{t("GENERAL.ERROR")}</Box>
  ) : mailDetails ? (
    <>
        <Card sx={{ flex: 2,  maxHeight: "1000px", overflow: "auto" }}>
          {/* Mail Header Menu */}
          <AdminMailDetailsMenu
            mailsSchema={mailsSchema}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            totalEmails={totalEmails}
            activeIndex={activeIndex}
            mailDetails={mailDetails}
            isLoadingData={isLoadingData}
            updateMailReadStatus={updateMailReadStatus}
            updateViewWithNewStatus={updateViewWithNewStatus}
            triggerFeedback={triggerFeedback}
          ></AdminMailDetailsMenu>

          {/* Mail content */}
          <CardContent sx={{ pt: 0, pb: 2, pl: 3, pr: 3 }}>
            <Divider sx={{ mb: 2 }} />
            {isLoadingData ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: "100px" }}>
                <CircularProgress />
              </Box>
            ) : (
              <AdminMailDetailsContent
                mailDetails={mailDetails}
                attachmentSchema={attachmentSchema}
              ></AdminMailDetailsContent>
            )}
          </CardContent>
        </Card>

      {/* Request Feedback */}
      <Feedback type={showFeedback} onClose={setShowFeedback}></Feedback>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", mt: "100px" }}>
      <CircularProgress />
    </Box>
  );
};

export default AdminMailDetails;
