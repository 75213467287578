import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { CssBaseline, LinearProgress, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import useAuthenticatedFetch from "./auth/authenticated";
import { MsalModel } from "./auth/msal.model";
import { User } from "./models/User";
import Footer from "./components/ui/Footer";
import Header from "./components/ui/Header";
import PageNotFound from "./pages/404";
import ClaimsList from "./pages/ClaimsList";
import Dashboard from "./pages/Dashboard";
import DashboardAdmin from "./pages/DashboardAdmin";
import LoginPage from "./pages/Login";
import NewMail from "./pages/NewMail";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import Search from "./pages/Search";
import { useThemeContext } from "./theme/ThemeContextProvider";
import InactivityModal from "./components/claim/modals/InactivityModal";
import Feedback from "./components/ui/Feedback";
import { FEEDBACK } from "./models/Feedback";

function App() {
  // Determine if a specific user was signed in
  const key = localStorage.getItem("ms");
  let accountIdentifiers: any;
  let msal: MsalModel;
  if (key) {
    msal = JSON.parse(key);
    accountIdentifiers = {
      localAccountId: msal.localAccountId,
      homeAccountId: msal.homeAccountId,
      username: msal.username,
    };
  }

  // state hooks
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  const [user, setUser] = useState<User | null>(null);
  const [fetchUser, setFetchUser] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  // MS auth
  const isAuthenticated = useIsAuthenticated(accountIdentifiers);
  // const { instance } = useMsal();
  const getAccessHeader = useAuthenticatedFetch();

  // For page reload
  useEffect(() => {
    if (isAuthenticated && !user) {
      handleLogin().then();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  // For debugging / error handling related to MSAL
  // useEffect(() => {
  //   const callbackId = instance.addEventCallback((message) => {
  //     //  console.log(message);
  //     if (message.eventType === EventType.LOGIN_FAILURE) {
  //       if (
  //         message.error instanceof AuthError &&
  //         message.error.errorCode !== "user_cancelled"
  //       ) {
  //         // handle errors
  //       }
  //     }
  //   });
  //
  //   return () => {
  //     // This will be run on component unmount
  //     if (callbackId) {
  //       instance.removeEventCallback(callbackId);
  //     }
  //   };
  // }, [instance]);

  /**
   * Callback to log in to App after MS login or on page reload
   */
  const handleLogin = async (redirectAfterLogin = false) => {
    // do not perform API call that will fail when user logged out in different tab or window
    if (localStorage.getItem("ms") === null) {
      console.log('User logged out in different tab or window.');
      // clear URL
      navigate('/', {replace: true});

      return;
    }

    // reset possible last state when MS user was not allowed for App
    setLoginError(false);
    setFetchUser(true);
    setShowFeedback(FEEDBACK.NONE);

    const user: User | null = await getUserData();
    setFetchUser(false);

    if (user) {
      setUser(() => user);
      // Redirect logged-in user away from login screen (case: not a page reload)
      if (redirectAfterLogin) {
        navigate(user.isAdmin ? "/admin" : "/claim", { replace: true });
      }
    } else {
      // MS user not valid for app
      setLoginError(true);
      setShowFeedback(FEEDBACK.ERROR);
    }
  };

  /**
   * API call to get user object
   */
  const getUserData = async (): Promise<User | null> => {
    try {
      const response = await fetch(`/api/me`, {
        method: "GET",
        headers: await getAccessHeader(),
      });

      if (response.status === 200) {
        const data = await response.json();

        return {
          isSuperAdmin: data.user_roles.indexOf("superadmin") > -1,
          isAdmin: data.user_roles.indexOf("admin") > -1,
          isOperator: data.user_roles.indexOf("operator") > -1,
          firstName: data.user_first_name,
          lastName: data.user_last_name,
          tenantId: data.tenant_id,
          roles: data.user_roles
        };
      } else {
        console.error(response.status);

        return null;
      }
    } catch (error) {
      console.error(error);

      return null;
    }
  };

    /**
     * Callback to log out from App
     */
    const logoutFromApp = async () => {
      try {
          const response = await fetch(`/api/logout`, {
              method: 'PUT',
              headers: await getAccessHeader(),
          });

        if (response.ok) {
          setUser(null);
          // clear MSAL data - log out for all tabs/windows
          localStorage.removeItem("ms");
          // clear URL
          navigate("/", { replace: true });
        } else {
          const data = await response.json();
          setShowFeedback(FEEDBACK.ERROR);
          console.error("Logout error: ", data.error);
        }
      } catch (error) {
        setShowFeedback(FEEDBACK.ERROR);
        console.error(error);
      }
  };

  // Case: While user data is being fetched first time OR on page reload
  if (fetchUser) {
    return <LinearProgress sx={{ margin: "50vh 50px 0 50px" }} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="header">
        <Header user={user} onLogout={logoutFromApp} />
      </div>

      <div className="content">
        {/* Login screen is only thing rendered when user has no MS authentication */}
        <UnauthenticatedTemplate>
          <LoginPage callback={handleLogin} userNotKnown={false} />
        </UnauthenticatedTemplate>

        {/* Routes are enabled after MS authentication */}
        <AuthenticatedTemplate>
          <InactivityModal user={user} inactiveCallback={logoutFromApp}></InactivityModal>

          {/* Case: MS user not known for App OR user logged out from App */}
          {!user && <LoginPage callback={handleLogin} userNotKnown={loginError} />}

          {user && (
            <Routes>
              {/* Protected routes which require user-specific roles */}
              <Route element={<ProtectedRoute isAllowed={user.isSuperAdmin || user.isAdmin} redirectPath={"/claim"}/>}>
                <Route path="/admin" element={<DashboardAdmin user={user} />} />
              </Route>

              <Route element={<ProtectedRoute isAllowed={user.isOperator} redirectPath={"/"} />}>
                <Route path="/claim" element={<Dashboard user={user} />} />
                <Route path="/claim/newmail" element={<NewMail />} />
                <Route path="/claims/search" element={<Search showExtraActions={user.isAdmin} />} />
                <Route path="/claims" element={<ClaimsList />} />
                <Route path="/claims/:id" element={<Dashboard user={user} />} />
              </Route>

              {/* Protected routes which require only authentication */}

              {/* Redirect to 404 page */}
              <Route path="*" element={<PageNotFound user={user} />} />
            </Routes>
          )}
        </AuthenticatedTemplate>

        {/* Error Feedback */}
        <Feedback type={showFeedback} onClose={setShowFeedback}></Feedback>
      </div>

      <div className="footer">
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
