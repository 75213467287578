import { Box } from "@mui/material";
import React from "react";
import CellRenderer from "./CellRenderer";

/**
 * Renders a detailed view of a table row when it is expanded.
 * It displays additional columns that are not shown in the main table but are
 *  available in the detail panel.
 */
export const DetailPanel = (row, detailPanelColumns, isEditMode, fieldErrors, onCellValueChange) => {
    // Filter out columns that are hidden, and show them in the detail panel
    const detailColumns = detailPanelColumns.filter(column => column.renderColumn === false);

    return row && detailColumns.length > 0 ? (
            <Box
                sx={{
                    width: '50%',
                }}
            >
                {detailColumns.map(column => (
                    <React.Fragment key={column.accessorKey}>
                        {React.Children.only(
                            <Box sx={{mt: 2}}>
                                <CellRenderer
                                    fieldErrors={fieldErrors}
                                    rowId={row.original.id}
                                    cellData={row.original[column.accessorKey]}
                                    column={column}
                                    isEditMode={isEditMode}
                                    isDetailPanel={true}
                                    onCellValueChange={onCellValueChange}
                                />
                            </Box>

                        )}
                    </React.Fragment>
                ))}
            </Box>
        ) :
        null
}
