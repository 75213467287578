import React from "react";
import { FEEDBACK } from "../../models/Feedback";
import { AUTO_HIDE_DURATION_ERROR, AUTO_HIDE_DURATION_SUCCESS } from "../../Common";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { common } from "../../theme/colors";
import { ReactComponent as CloseIconWhite } from "../../assets/close-white.svg";
import { ReactComponent as ErrorIcon } from "../../assets/minus-circle.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/check-circle.svg";
import { useTranslation } from "react-i18next";

interface FeedbackProps {
  /**
   * Triggers the display of the component from outside and defines it appearance type.
   * FEEDBACK.SUCCESS | FEEDBACK.ERROR shows it.
   * FEEDBACK.NONE hides it.
   */
  type: FEEDBACK;

  /**
   * Callback when component closed after auto hide duration time or via click on close icon.
   * Returns FEEDBACK.NONE.
   */
  onClose: (type: FEEDBACK.NONE) => void;

  /**
   * Optional. Custom (translated) success message.
   * Default is "GENERAL.SAVED".
   */
  successMessage?: string;

  /**
   * Optional. Custom (translated) error message.
   * Default is "GENERAL.ERROR".
   */
  errorMessage?: string;
}

const Feedback: React.FC<FeedbackProps> = ({type, onClose, successMessage, errorMessage}) => {
    const { t } = useTranslation();

    const handleFeedbackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      // ignore clicks that are not on the close icon of the alert
      if (reason === "clickaway") {
        return;
      }
      // callback to parent to update state
      onClose(FEEDBACK.NONE);
    };

    // Hide Feedback
    if (type === FEEDBACK.NONE) {
        return null;
    }
    
    return (
        <Snackbar
            open={true}
            autoHideDuration={
                type === FEEDBACK.SUCCESS
                    ? AUTO_HIDE_DURATION_SUCCESS
                    : AUTO_HIDE_DURATION_ERROR
            }
            onClose={handleFeedbackClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <Alert
                variant="filled"
                severity={type === FEEDBACK.SUCCESS ? "success" : "error"}
                icon={type === FEEDBACK.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />}
                sx={{ color: common["white"] }}
                action={
                    <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                        <CloseIconWhite />
                    </IconButton>
                }
            >
                {type === FEEDBACK.ERROR
                    ? (errorMessage ? errorMessage : t("GENERAL.ERROR"))
                    : (successMessage ? successMessage : t("GENERAL.SAVED"))
                }
            </Alert>
        </Snackbar>
    );
};

export default Feedback;
