import {ColumnData, TableColumn} from "../../models/Table";

// Custom filter function for UUID and Enum columns
const customUUIDFilter = (options) => {
    return (row, columnId, filterValue) => {
        const value = row.getValue(columnId);
        const translatedValue = options[value] || value;
        // Perform a case-insensitive comparison between translated value and filter value
        return String(translatedValue).toLowerCase().includes(String(filterValue).toLowerCase());
    };
};


/**
 * Generate table columns based on the provided column data and constraints.
 * @param columnData - schema data
 * @param columnsToExclude - schema data to be excluded
 * @param t - translate function ref
 * @param id - name of the table tab
 */
export const generateColumns = (columnData: ColumnData[], columnsToExclude: string[], t: (key: string) => string, id: string) => {
    const COLUMNS_TO_SHOW: number = id === 'claims' ? 7 : 6;
    const columns: TableColumn[] = [];

    // Iterate over each column data entry
    Object.entries(columnData)
        .filter(([key]) => !columnsToExclude.includes(key)) // Exclude specified columns
        .forEach(([key, item], index) => {
            // Create a column definition based on the type of column data
            switch (item.type) {
                case 'Text':
                case 'JSON':
                case 'ARRAY':
                case 'Integer':
                case 'DateTime':
                case 'Float':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        nullable: item.constraints.nullable,
                        maxLength: item.constraints.max_length,
                        isEditable: item.constraints.is_editable
                    });
                    break;

                case 'Enum':
                case 'UUID':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        options: item.constraints.options,
                        nullable: item.constraints.nullable,
                        isEditable: item.constraints.is_editable,
                        filterFn: customUUIDFilter(item.constraints.options) // Custom filter for UUID/Enum
                    });
                    break;

                case 'Boolean':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        nullable: item.constraints.nullable,
                        isEditable: item.constraints.is_editable
                    });
                    break;
            }

            // Hide columns beyond the maximum number allowed to be shown
            if (index >= COLUMNS_TO_SHOW) {
                columns[columns.length - 1].renderColumn = false;
            }
        });

    return columns;
};
